/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  @apply outline-none;
}

/*
  Optionally: Define a strong focus indicator for keyboard focus.
  If you choose to skip this step then the browser's default focus
  indicator will be displayed instead.
*/

[data-js-focus-visible] [data-focus-visible-added] {
  @apply outline-none ring-2 ring-brand-red;
}
