[data-module-navigator] {
  @apply w-full flex-shrink-0 py-8 lg:max-w-[420px] lg:py-0;
  [data-module-navigator-header] {
    @apply flex w-full items-center justify-between pb-3;
    /* Contents */
    [data-title] {
      @apply text-2xl font-semibold;
    }
    [data-byline] {
      @apply cursor-pointer font-mono text-sm font-semibold uppercase text-gray-600;
    }
  }
  [data-sections] {
    @apply flex flex-col gap-2;
    [data-section] {
      [data-header] {
        @apply relative z-10 overflow-hidden rounded-lg bg-white;
        [data-trigger] {
          @apply relative z-10 flex w-full items-center justify-between rounded-lg border border-gray-200 bg-white px-3 py-2.5 text-left text-lg font-medium leading-tight shadow-lg transition hover:bg-gray-100;
          [data-icons] {
            @apply flex items-center;
            [data-check] {
              @apply mr-2 h-4 w-4 text-emerald-600;
            }
            [data-chevron] {
              /* group-hover:opacity-100 group-radix-state-open:rotate-180 */
              @apply relative h-3 w-3 opacity-70 transition;
            }
          }
        }
        [data-trigger]:hover {
          [data-chevron] {
            @apply opacity-100 radix-state-open:rotate-180;
          }
        }
        [data-progress] {
          @apply absolute left-0 top-0 h-full bg-gray-500/10;
        }
      }

      [data-lessons] {
        @apply -mt-5 rounded-b-lg border border-gray-200 bg-stone-50 pb-3 pt-7;
      }
    }
    [data-lesson] {
      a {
        @apply inline-flex w-full flex-col justify-center py-2.5 pl-3.5 pr-3 text-base font-medium;
        [data-title-container] {
          @apply inline-flex items-center;
          [data-title] {
            @apply w-full cursor-pointer leading-tight;
          }
          [data-check-icon] {
            @apply -ml-1 mr-[11.5px] h-4 w-4 text-emerald-600;
          }
          [data-index] {
            @apply w-6 font-mono text-xs text-gray-400;
          }
          [data-lock-icon] {
            @apply -ml-1 mr-[11.5px] h-4 w-4 text-gray-400;
          }
        }
        [data-continue] {
          @apply flex items-center gap-1 pb-1;
          [data-icon] {
            @apply -ml-0.5 mr-2 h-3 w-3 text-primary;
          }
          [data-label] {
            @apply font-mono text-xs font-semibold uppercase tracking-wide text-primary;
          }
        }
      }
      a:hover {
        [data-title] {
          @apply underline;
        }
      }
      a[data-is-in-section='false'] {
        @apply rounded-md px-0 py-1;
      }
      a[data-is-highlighted='true'] {
        @apply bg-gradient-to-r from-gray-500/5 to-transparent;
      }
    }
  }
}

[data-module-navigator-skeleton] {
  @apply flex w-full flex-shrink-0 animate-pulse flex-col gap-3 sm:max-w-sm;
  [data-header] {
    @apply flex w-full items-center justify-between pb-3;
    [data-title] {
      @apply text-2xl font-semibold;
    }
    [data-byline] {
      @apply cursor-pointer font-mono text-sm font-semibold uppercase text-gray-600;
    }
  }
  [data-section] {
    @apply flex flex-col gap-3 pb-5;
    [data-header] {
      @apply h-4 w-5/6 rounded-full bg-gray-200;
    }
    [data-lesson] {
      @apply h-3 rounded-full bg-gray-100;
    }
  }
  [data-lesson] {
    @apply flex flex-col;
    div {
      @apply h-5 rounded-full bg-gray-200;
    }
  }
}
