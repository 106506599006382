h1[data-lesson-title] {
  @apply pb-5 pt-3 text-3xl w-full font-bold font-heading sm:text-4xl md:text-5xl 2xl:text-4xl;
}

[data-lesson-badge] {
  @apply inline-block rounded-sm font-mono text-xs font-semibold uppercase sm:mt-5 lg:text-sm 2xl:mt-0 2xl:text-xs;
}
[data-lesson-badge='lesson'] {
  @apply text-gray-600;
}
[data-lesson-badge='solution'] {
  @apply text-gray-600;
}
[data-lesson-badge='exercise'] {
  @apply text-gray-600;
}
[data-lesson-badge='explainer'] {
  @apply text-gray-600;
}
[data-lesson-badge='interview'] {
  @apply text-gray-600;
}
