[data-video-transcript] {
  @apply mx-auto max-w-4xl;
  [data-title] {
    @apply flex items-baseline text-3xl font-semibold sm:text-4xl font-heading;
  }
  [data-transcript] {
    @apply prose max-w-none pt-4;
    [data-timestamp] {
      @apply inline-block underline after:inline-block after:content-['_'];
    }
  }
}
