@tailwind base;
@tailwind components;

@import './subscribe-form.css';
@import './nprogress.css';
@import './fonts.css';
@import './prism.css';
@import './tips.css';
@import './login.css';

@import '@code-hike/mdx/dist/index.css';

@import './video/github-link.css';
@import './video/lesson-completion-toggle.css';
@import './video/lesson-description.css';
@import './video/lesson-title.css';
@import './video/module-lesson-list.css';
@import './video/video-overlays.css';
@import './video/video-transcript.css';
@import './video/module-navigator.css';

@tailwind utilities;
@import './focus-visible.css';

mux-player {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  /* margin-bottom: -6px; */
}

mux-player::part(center play button) {
  @apply sm:scale-50;
}

mux-player::part(vertical-layer) {
  background-color: rgba(0, 0, 0, 0);
}

::selection {
  @apply bg-brand-bone;
}

.ch-codeblock {
  box-shadow: 0px 0.3px 2.1px rgba(0, 0, 0, 0.014),
    0px 0.8px 5px rgba(0, 0, 0, 0.02), 0px 1.5px 9.4px rgba(0, 0, 0, 0.025),
    0px 2.7px 16.8px rgba(0, 0, 0, 0.03), 0px 5px 31.3px rgba(0, 0, 0, 0.036),
    0px 12px 75px rgba(0, 0, 0, 0.05);
}

.cm-editor {
  [data-focus-visible-added] {
    @apply outline-none ring-0 border-none;
  }
}

@layer base {
  :root {
    --background: 60 4.8% 95.9%;
    --foreground: 24 9.8% 10%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 11.4 66.2% 59.4%;
    --primary-foreground: 0 0% 100%;

    --secondary: 37 64.9% 59.8%;
    --secondary-foreground: 37 49.4% 15.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 47.8 9.5% 80.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 227 47% 6%;
    --foreground: 220 13% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 227 100% 68%;
    --primary-foreground: 0 0% 100%;

    --secondary: 222.2 47.4% 13.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply overflow-y-scroll bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}
