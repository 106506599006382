[data-lesson-description] {
  [data-content] {
    @apply prose lg:prose-lg 2xl:prose-base sm:prose-h2:text-4xl relative max-w-none lg:prose-h2:text-3xl lg:prose-h3:text-2xl lg:prose-h4:text-xl prose-h2:text-3xl prose-h3:text-2xl prose-h4:text-xl;
  }
  [data-content-visible='false'] {
    @apply after:absolute after:bottom-0 after:left-0 after:h-1/2 after:w-full after:bg-gradient-to-b after:from-transparent after:via-gray-100 after:to-gray-100 after:content-[""];
  }
  div[role='status'] {
    @apply flex w-full -translate-y-5 animate-pulse flex-col gap-3;
    div:nth-child(1) {
      @apply h-3 w-2/3 rounded-full bg-gray-200;
    }
    div:nth-child(2) {
      @apply h-3 rounded-full bg-gray-200;
    }
    div:nth-child(3) {
      @apply h-3 w-1/2 rounded-full bg-gray-200;
    }
    div:nth-child(4) {
      @apply h-3 w-5/6 rounded-full bg-gray-200;
    }
    div:nth-child(5) {
      @apply h-3 w-2/5 rounded-full bg-gray-200;
    }
    div:nth-child(6) {
      @apply h-3 w-1/3 rounded-full bg-gray-200;
    }
  }
  [data-cta] {
    @apply relative flex -translate-y-8 items-center justify-center rounded border border-gray-200 bg-white p-5 before:absolute before:top-[-8px] before:h-4 before:w-4 before:rotate-45 before:border-l before:border-t before:border-gray-200 before:bg-white before:content-[''];
    p {
      @apply prose max-w-none;
    }
  }
}
